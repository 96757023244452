html {
    width: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-height: 100%;

    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1024px) {
    .break-on-large {
        display: none;
    }
}

/* Extras */
.apexcharts-tooltip {
    left: 5px !important;
}
.translate-link {
    color: white;
    font-weight: bold;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 38px;
}
::-webkit-scrollbar-track:hover {
    background: #666666;
}
::-webkit-scrollbar-track:active {
    background: #333333;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
input:focus {
    outline: none;
}

.error {
    color: #ff5666;
}
.bold {
    font-weight: bold;
}

.intercom-lightweight-app {
    z-index: 1 !important;
}

@media only screen and (min-width: 1280px) {
    .cookieConsent {
        width: calc(100vw - 200px) !important;
    }
}

.emphasize {
    -moz-animation: hoverUpDown 500ms ease-in-out;
    -o-animation: hoverUpDown 500ms ease-in-out;
    animation: hoverUpDown 500ms ease-in-out;
}

/* animation */
@keyframes hoverUpDown {
    0% {
        -webkit-transform: translateY(0);
        filter: brightness(1);
    }
    50% {
        -webkit-transform: translateY(-0.15em);
        filter: brightness(0.8);
    }
    100% {
        -webkit-transform: translateY(0);
        filter: brightness(1);
    }
}

#background-image::before, 
#particles-js::before {
    content: '';
}

.grecaptcha-badge {
    display: none;
}

.matic-widget {
    transition: transform 0.5s ease !important;
}

.matic-widget__overlay::before {
    background-color: rgba(0, 0, 0, 0.55) !important;
}

.b-polyglon-notification__header--cancel {
    display: none !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border: none !important;
}

.migrationYTVideo {
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 1080px) {
    .migrationYTVideo {
        width: 100%;
    }
}